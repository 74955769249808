import * as React from "react";
import "../styles/style.css";

// markup
const CasesPage = () => {
  const toggleNav = () => {
    const mobileNav = document.getElementById("mobileNav");
    mobileNav.classList.toggle("visible");
  };
  return (
    <main>
      <title>Alexandra Rusu - Despre mine</title>
      <header>
        <span>Dr. Alexandra Rusu</span>
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
        <button className="menu-trigger" onClick={toggleNav}>
          Menu
        </button>
      </header>
      <div className="mobile-nav" id="mobileNav">
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
      </div>
      <section className="cases-page">
        <h1>Cazuri</h1>
        <div className="cases-list">
          <div className="case">
            <div className="image image-1"></div>
            <div className="text">
              <h3>Pacient sex masculin, 21 ani</h3>
              <p>
                Inghesuirea severa, inclinarea planului maxilar si asimetria
                gingivala au fost rezolvate printr-un tratament extractional cu
                aparat dentar fix. Durata tratamentului: 22 luni.
              </p>
            </div>
          </div>
          <div className="case">
            <div className="image image-2"></div>
            <div className="text">
              <h3>Pacienta sex feminin, 12 ani</h3>
              <p>
                Inghesuirea medie a fost rezolvata printr-un tratament
                nonextractional cu un aparat fix metalic, fara a proclina
                excesiv dintii. Durata tratamentului: 18 luni.
              </p>
            </div>
          </div>
          <div className="case">
            <div className="image image-3"></div>
            <div className="text">
              <h3>Pacienta sex feminin, 19 ani.</h3>
              <p>
                Un caz complex de inghesuire severa cu angrenaj invers (la
                nivelul incisivului lateral drept maxilar 1.2), devierea liniei
                interincisive, spatii postextractionale existente (inchidere cu
                evitarea consecutiva a aplicarii implanturilor dentare), cant
                maxilar si asimetrie gingivala. Problemele au fost rezolvate cu
                ajutorul unui aparat dentar fix in decursul a 25 luni.
              </p>
            </div>
          </div>
          <div className="case">
            <div className="image image-4"></div>
            <div className="text">
              <h3>Pacienta sex feminin, 23 ani.</h3>
              <p>
                O inghesuire medie rezolvata cu ajutorul unui aparat dentar fix
                si a “strippingului”. Durata tratamentului: 21 luni.
              </p>
            </div>
          </div>
          <div className="case">
            <div className="image image-5"></div>
            <div className="text">
              <h3>Pacienta sex feminin, 11 ani.</h3>
              <p>
                Dinti maxilari inclinati excesiv in urma obiceiului vicios de
                sugere a degetului, mandibula plasata posterior cu aparitia
                consecutiva a unei distante intre incisivii maxilari si
                mandibulari de aproximativ 1cm. Tratamentul efectuat cu aparat
                ortodontic fix si headgear a rezolvat problemele muscaturii si a
                imbunatatit considerabil aspectul facial.
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="col">
          <h3>
            Alexandra Rusu
            <br />
            <span>— medic specialist ortodontie</span>
            <br />
            <small>© 2021</small>
          </h3>
        </div>
        <div className="col">
          <a href="/contact">Contact</a>
        </div>
      </footer>
    </main>
  );
};

export default CasesPage;
